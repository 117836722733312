<template>
  <div
    @mousewheel="handleMouseWheel"
    @touchstart="touchStart"
    @touchmove="touchMove"
    style="position: relative; overflow: hidden"
  >
    <!-- <ThemeObserver :theme=theme /> -->
    <!-- <div class="recruit_btn">
      <a href="https://www.notion.so/wackyuxteam/2021-WACKY-COMPANY-Recruit-e25d7670855b4dc7aa68b7a49d90e973" target="_blank">
       <span class="emoji">&#x1F44B;</span>
       <span class="dot">2</span>
       <span class="btn_txt">Careers</span>
      </a>
    </div> -->
    <div class="scroll_btn"></div>
    <Visual id="visual" />
    <Home id="home" />
    <About id="about" />
    <div v-bind:style="{ position: 'relative' }">
      <ScrollObserver v-on:changeDirection="changeDirection" />
      <Works id="works" />
      <Contact id="contact" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref, watch } from "vue";
import Home from "@/components/main/Home.vue";
import About from "@/components/main/About.vue";
import Works from "@/components/main/Works.vue";
import Contact from "@/components/main/Contact.vue";
import ScrollObserver from "@/components/ScrollObserver.vue";
import Visual from "@/components/main/Visual.vue";
import { Direction } from "@/enums/Direction";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Main",
  components: {
    ScrollObserver,
    Visual,
    Home,
    About,
    Works,
    Contact,
    // ThemeObserver
  },
  data() {
    return {
      // theme: Theme.white
    };
  },
  setup() {
    let inMove = ref(false);
    let activeSection = ref(0);
    let fullPageMode = ref(true);
    let hashNames = ref(["#home", "#about", "#works"]);
    let recentInputTime = ref(0);

    let touchStartY = ref(-1);
    let tempTouchMode = ref(false);

    const route = useRoute();
    const router = useRouter();
    watch(
      () => route.hash,
      (hash) => {
        checkCurrentStatus(hash);
      }
    );

    const store = useStore();
    onMounted(() => {
      checkCurrentStatus(route.hash);
      store.commit("resetNaviTheme");
    });

    const scrollOption = {
      cancelable: false,
      onDone: function (el) {
        inMove.value = false;
      },
    };

    const vueScrollTo = require("vue-scrollto");
    const scrollToEl = (el: Element) => {
      vueScrollTo.scrollTo(el, scrollOption);
    };
    function setOverflow() {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.height = "100%";
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    }
    setOverflow();

    function checkCurrentStatus(hash: string) {
      if (hash.length == 0) {
        return;
      }

      inMove.value = true;
      // console.log("checkCurrentStatus : " + hash);
      const el = document.querySelector(hash) as HTMLElement;
      scrollToEl(el);

      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.height = "100%";
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";

      const index = hashNames.value.indexOf(hash);
      activeSection.value = index < 0 ? 0 : index;
    }

    function handleMouseWheel(e: WheelEvent) {
      const prevTime = recentInputTime.value;
      recentInputTime.value = Date.now();

      if (inMove.value) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      if (!fullPageMode.value) {
        document.documentElement.style.overflow = "auto";
        document.body.style.overflow = "auto";
        // document.documentElement.style.height = 'auto';
        // document.body.style.height = 'auto';
        return true;
      }

      e.preventDefault();
      e.stopPropagation();

      const pastTime = recentInputTime.value - prevTime;
      const value = pastTime * pastTime * e.deltaY;
      if (Math.abs(value) < 10000) {
        return false;
      }

      if (e.deltaY > 0) {
        // 마지막 풀페이지에서 스크롤 다운 하면 풀 페이지모드 해제
        if (
          fullPageMode.value &&
          activeSection.value >= hashNames.value.length - 1
        ) {
          fullPageMode.value = false;
          return true;
        }

        moveDown();
      } else if (e.deltaY < 0) {
        moveUp();
      }
      return false;
    }

    function moveUp() {
      const currentSection = activeSection.value - 1;
      // console.log("moveUp change : " + currentSection);
      if (inMove.value || currentSection < 0) {
        return;
      }

      routerMove(hashNames.value[currentSection]);
    }

    function moveDown() {
      const currentSection = activeSection.value + 1;
      if (inMove.value || currentSection >= hashNames.value.length) {
        return;
      }

      routerMove(hashNames.value[currentSection]);
    }

    const routerMove = (to: string) => {
      router.push({
        name: "Main",
        hash: to,
        query: route.query,
      });
    };

    function touchStart(e: TouchEvent) {
      if (inMove.value) {
        e.preventDefault();
        return false;
      }

      tempTouchMode.value = false;
      if (
        fullPageMode.value &&
        activeSection.value >= hashNames.value.length - 1
      ) {
        tempTouchMode.value = true;
        document.documentElement.style.overflow = "auto";
        document.body.style.overflow = "auto";
        touchStartY.value = e.touches[0].clientY;
        return true;
      }

      if (!fullPageMode.value) {
        return true;
      }

      e.preventDefault();
      touchStartY.value = e.touches[0].clientY;
    }

    function touchMove(e: TouchEvent) {
      if (touchStartY.value < 0) {
        e.preventDefault();
        return false;
      }

      const currentY = e.touches[0].clientY;
      if (
        tempTouchMode.value &&
        fullPageMode.value &&
        touchStartY.value > currentY
      ) {
        fullPageMode.value = false;
        return true;
      }

      if (!fullPageMode.value) {
        return true;
      }

      if (touchStartY.value < currentY) {
        moveUp();
      } else {
        moveDown();
      }

      touchStartY.value = -1;
      return false;
    }

    function changeDirection(direction: Direction) {
      if (direction == Direction.up && !fullPageMode.value) {
        fullPageMode.value = true;
        moveUp();
      }
    }

    return {
      handleMouseWheel,
      touchStart,
      touchMove,
      changeDirection,
      setOverflow,
    };
  },
});
</script>

<style scoped lang="css">
.fullpage {
  position: relative;
  height: 100%;
  width: 100%;
}

.second_page {
  position: relative;
  width: 100%;
  height: 100%;
}

/* btn */

.scroll_btn {
  position: fixed;
  right: 4vw;
  bottom: 12vh;
  z-index: 200;
  animation-name: scrollbtn_ani;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.scroll_btn::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 70px;
  background: #99999969;
}

.scroll_btn::before {
  content: "";
  position: absolute;
  bottom: -64px;
  left: -2px;
  width: 15px;
  height: 1px;
  background: #99999969;
  transform: rotate(-54deg);
}

@keyframes scrollbtn_ani {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(2vh);
  }
  6% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(2vh);
  }
  14% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.recruit_btn {
  width: 140px;
  height: 52px;
  position: fixed;
  left: 4vw;
  bottom: 7vh;
  z-index: 300;
  cursor: pointer;
}

.recruit_btn::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #444;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  border-radius: 26px;
  cursor: pointer;
}

.recruit_btn .btn_txt {
  position: absolute;
  right: 3px;
  width: 100px;
  height: 52px;
  text-align: center;
  line-height: 48px;
  font-size: 20px;
  color: #fff;
  z-index: 400;
}

.recruit_btn .emoji {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 2px;
  text-align: center;
  line-height: 52px;
  font-size: 30px;
  z-index: 400;
  animation-name: recruit_btn;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  transition: 0.2s ease-in-out;
}

.recruit_btn .dot {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px;
  height: 20px;
  background: red;
  z-index: 300;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  z-index: 300;
  transition: 140ms ease-in-out;
  animation: dot_jump 7s ease-in-out both infinite;
  font-family: "Roboto", sans-serif;
}

.recruit_btn .dot::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  z-index: -100;
  filter: blur(2px);
  animation-name: recruit_dot1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

@keyframes recruit_btn {
  0% {
    transform: rotate(-12deg);
  }

  40% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-12deg);
  }
}

@keyframes recruit_dot1 {
  0% {
    opacity: 0.1;
    transform: scale(0);
  }

  10% {
    opacity: 0.3;
    transform: scale(1.4);
  }

  20% {
    opacity: 0.2;
    transform: scale(1.2);
  }
  21% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes recruit_dot2 {
  0% {
    opacity: 0.1;
    transform: scale(0);
  }
  50% {
    opacity: 0.3;
    transform: scale(1.4);
  }
  100% {
    opacity: 0.2;
    transform: scale(1.2);
  }
}

@keyframes dot_jump {
  0% {
    transform: translateY(0px);
  }
  1.5% {
    transform: translateY(-5px);
  }
  3% {
    transform: translateY(0px);
  }
  4.5% {
    transform: translateY(-5px);
  }
  6% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

.recruit_btn:hover .emoji {
  animation-play-state: paused;
}

.recruit_btn:hover .dot {
  top: -10px;
}

.recruit_btn:hover .dot::after {
  animation-name: recruit_dot2;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

/*__btn*/

/* 반응형 */

@media screen and (max-width: 1280px) {
} /*__end*/

@media screen and (max-width: 1024px) {
  .scroll_btn::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 56px;
  }
  .scroll_btn::before {
    content: "";
    position: absolute;
    bottom: -51px;
    left: -2px;
    width: 12px;
    transform: rotate(-54deg);
  }

  @keyframes scrollbtn_ani {
    0% {
      transform: translateY(0);
    }

    4% {
      transform: translateY(2vh);
    }

    6% {
      transform: translateY(0);
    }

    10% {
      transform: translateY(2vh);
    }

    14% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(0);
    }
  }
} /*__end*/

@media screen and (max-width: 870px) {
} /*__end*/

@media screen and (max-width: 768px) {
} /*__end*/

@media screen and (max-width: 550px) {
  .scroll_btn {
    right: 6.66666vw;
  }
} /*__end*/

@media screen and (max-width: 425px) {
} /*__end*/

/*__반응형*/
</style>
