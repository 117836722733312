
import { defineComponent } from "vue";
import ThemeObserver from "@/components/ThemeObserver.vue";
import { Theme } from "@/enums/Theme";

export default defineComponent({
  name: "Home",
  data() {
    return {
      show0: false,
      theme: Theme.hide,
    };
  },
  components: {
    ThemeObserver,
  },
  methods: {
    isShowObserver0(value: boolean) {
      this.show0 = value;
    },
  },
});
