
import { defineComponent, inject, onMounted, ref, watch } from "vue";
import Home from "@/components/main/Home.vue";
import About from "@/components/main/About.vue";
import Works from "@/components/main/Works.vue";
import Contact from "@/components/main/Contact.vue";
import ScrollObserver from "@/components/ScrollObserver.vue";
import Visual from "@/components/main/Visual.vue";
import { Direction } from "@/enums/Direction";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Main",
  components: {
    ScrollObserver,
    Visual,
    Home,
    About,
    Works,
    Contact,
    // ThemeObserver
  },
  data() {
    return {
      // theme: Theme.white
    };
  },
  setup() {
    let inMove = ref(false);
    let activeSection = ref(0);
    let fullPageMode = ref(true);
    let hashNames = ref(["#home", "#about", "#works"]);
    let recentInputTime = ref(0);

    let touchStartY = ref(-1);
    let tempTouchMode = ref(false);

    const route = useRoute();
    const router = useRouter();
    watch(
      () => route.hash,
      (hash) => {
        checkCurrentStatus(hash);
      }
    );

    const store = useStore();
    onMounted(() => {
      checkCurrentStatus(route.hash);
      store.commit("resetNaviTheme");
    });

    const scrollOption = {
      cancelable: false,
      onDone: function (el) {
        inMove.value = false;
      },
    };

    const vueScrollTo = require("vue-scrollto");
    const scrollToEl = (el: Element) => {
      vueScrollTo.scrollTo(el, scrollOption);
    };
    function setOverflow() {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.height = "100%";
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    }
    setOverflow();

    function checkCurrentStatus(hash: string) {
      if (hash.length == 0) {
        return;
      }

      inMove.value = true;
      // console.log("checkCurrentStatus : " + hash);
      const el = document.querySelector(hash) as HTMLElement;
      scrollToEl(el);

      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.height = "100%";
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";

      const index = hashNames.value.indexOf(hash);
      activeSection.value = index < 0 ? 0 : index;
    }

    function handleMouseWheel(e: WheelEvent) {
      const prevTime = recentInputTime.value;
      recentInputTime.value = Date.now();

      if (inMove.value) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      if (!fullPageMode.value) {
        document.documentElement.style.overflow = "auto";
        document.body.style.overflow = "auto";
        // document.documentElement.style.height = 'auto';
        // document.body.style.height = 'auto';
        return true;
      }

      e.preventDefault();
      e.stopPropagation();

      const pastTime = recentInputTime.value - prevTime;
      const value = pastTime * pastTime * e.deltaY;
      if (Math.abs(value) < 10000) {
        return false;
      }

      if (e.deltaY > 0) {
        // 마지막 풀페이지에서 스크롤 다운 하면 풀 페이지모드 해제
        if (
          fullPageMode.value &&
          activeSection.value >= hashNames.value.length - 1
        ) {
          fullPageMode.value = false;
          return true;
        }

        moveDown();
      } else if (e.deltaY < 0) {
        moveUp();
      }
      return false;
    }

    function moveUp() {
      const currentSection = activeSection.value - 1;
      // console.log("moveUp change : " + currentSection);
      if (inMove.value || currentSection < 0) {
        return;
      }

      routerMove(hashNames.value[currentSection]);
    }

    function moveDown() {
      const currentSection = activeSection.value + 1;
      if (inMove.value || currentSection >= hashNames.value.length) {
        return;
      }

      routerMove(hashNames.value[currentSection]);
    }

    const routerMove = (to: string) => {
      router.push({
        name: "Main",
        hash: to,
        query: route.query,
      });
    };

    function touchStart(e: TouchEvent) {
      if (inMove.value) {
        e.preventDefault();
        return false;
      }

      tempTouchMode.value = false;
      if (
        fullPageMode.value &&
        activeSection.value >= hashNames.value.length - 1
      ) {
        tempTouchMode.value = true;
        document.documentElement.style.overflow = "auto";
        document.body.style.overflow = "auto";
        touchStartY.value = e.touches[0].clientY;
        return true;
      }

      if (!fullPageMode.value) {
        return true;
      }

      e.preventDefault();
      touchStartY.value = e.touches[0].clientY;
    }

    function touchMove(e: TouchEvent) {
      if (touchStartY.value < 0) {
        e.preventDefault();
        return false;
      }

      const currentY = e.touches[0].clientY;
      if (
        tempTouchMode.value &&
        fullPageMode.value &&
        touchStartY.value > currentY
      ) {
        fullPageMode.value = false;
        return true;
      }

      if (!fullPageMode.value) {
        return true;
      }

      if (touchStartY.value < currentY) {
        moveUp();
      } else {
        moveDown();
      }

      touchStartY.value = -1;
      return false;
    }

    function changeDirection(direction: Direction) {
      if (direction == Direction.up && !fullPageMode.value) {
        fullPageMode.value = true;
        moveUp();
      }
    }

    return {
      handleMouseWheel,
      touchStart,
      touchMove,
      changeDirection,
      setOverflow,
    };
  },
});
